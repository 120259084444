* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    list-style: none;
}

p,
label {
    font-size: 15px;
    line-height: 1.7em;
    color: #333333;
    font-weight: 400;
}

i {
    font-size: 15px;
    line-height: 1.7em;
    color: #333333;
    font-weight: 400;
}

h1 {
    font-size: 51px;
    line-height: 1.06em;
    font-weight: 900;
    margin-bottom: 24px;
}

@media(max-width:600px) {
    h1 {
        font-size: 45px;
    }
}

h2 {
    font-size: 26px;
    line-height: 1.3em;
    font-weight: 900;
}

h3 {
    font-size: 18px;
    line-height: 1.3em;
    font-weight: 300;
    font-style: italic;
}

.small-type {
    font-size: 12px;
}

.red {
    color: #BA0C2F;
}

.timecount {
    color: #BA0C2F;
    font-weight: 300;
    font-size: 3.6em;
    padding: 400px 0px 400px 0px;
}



.dates {
    font-size: 24px;
    line-height: 1.5em;
    font-weight: 300;
}

.block {
    display: block;
}

button {
    cursor: pointer;
    border: 0;
    background: none;
}

.button {
    font-size: 23px;
    line-height: 1.6em;
    color: white;
    background: #BA0C2F;
    padding: .4em 1.5em .5em 1.5em;
    border-radius: 10px;
    display: inline;
    color: white;
    text-decoration: none;
}

a.button,
a.button:visited {
    color: white;
}

.button-small {
    font-size: 14px;
    line-height: .8 em;
    color: white;
    background: #BA0C2F;
    padding: .4em 1.5em .5em 1.5em;
    border-radius: 5px;
    display: inline;
    color: white;
    text-decoration: none;
}

.link {
    color: #BA0C2F;
    text-decoration: underline;
    border: none;
    /* text-transform: uppercase; */
    font-size: 12px;
}

.pause {
    margin-top: 40px;
    margin-bottom: 20px;
    min-width: 50%;
}

@media(max-width:600px) {
    .button {
        align-self: center;
    }
}

.pointer {
    cursor: pointer;
}

.register {
    font-size: 20px;
    line-height: 1.6em;
    color: white;
    background: #BA0C2F;
    padding: .4em 1.5em .5em 1.5em;
    border-radius: 10px;
    display: inline;
    color: white;
    text-decoration: none;
    border: none;
}

.start_button_ring {
    position: relative;
    align-self: flex-start;
    margin-left: 10%;
    font-size: 26px;
}

.start_button_ring::before {
    background: url(../images/timer_start_bg.svg);
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* animation: rotate 60s linear infinite; */
}

.timer {
    padding: 45px 0;
}

.timer-active {
    text-align: center;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.timer-active .timer-center {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.horizontal_center {}

@media(max-width:400px) {
    .start_button_ring {
        align-self: center;
        margin-left: 0;
    }
}

.red-text {
    color: #BA0C2F;
}

.black-text {
    color: #333333;
}

.white-text {
    color: white;
}

.bold {
    font-weight: 900;
}

.small_dates {
    line-height: 1em;
}

header {
    width: 90vw;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 80px;
    padding-top: 20px;
}

@media(max-width:500px) {
    header {
        margin-bottom: 20px;
    }
}

.header_inner_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
}

.user-name_header {
    font-size: 16px;
    align-self: flex-end;
}

.App {
    min-height: 100vh;
}

.App>div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.main_wrapper {
    /*height: calc(100vh - 100px - 60px);*/
    width: 90vw;
    max-width: 800px;
    margin: 0 auto;
    flex-grow: 1;
}




.flex-row {
    display: flex;
    flex-direction: row;
}

@media(max-width:600px) {
    .flex-row {
        display: flex;
        flex-direction: column;
    }
}

.charlie-flex-row {
    display: flex;
    flex-direction: row;
}

@media(max-width:600px) {
    .column-mobile {
        display: flex;
        flex-direction: column;
    }
}

@media(max-width:600px) {
    .mobile_margin_bottom {
        margin-bottom: 45px;
    }
}

span.block_text {
    display: block;
}

@media(max-width:600px) {

    span.block_text,
    p.block_text {
        display: block;
    }
}

.right_align {
    text-align: right;
}

@media(max-width:600px) {
    .right_align {
        text-align: left;
    }
}

.justify_center {
    justify-content: center;
}

.justify_between {
    justify-content: space-between;
}

.align_bottom {
    align-items: flex-end;
}

@media(max-width:600px) {
    .align_bottom {
        align-items: flex-start;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row_timer {
    display: flex;
    flex-direction: row;
}

.left_column {
    width: 66%;
    max-width: 600px;
}

.right_column {
    width: 34%;

}

.right_timer_column {
    width: 60%;
}

@media(max-width:500px) {
    .right_timer_column {
        width: 100%
    }
}

.left_timer_column {
    width: 40%;
    position: unset;
}

@media(max-width:600px) {
    .right_column {
        max-width: 100%;
        width: 100%;
    }
}

.flex-row_write_again {
    display: flex;
    flex-direction: row;
}

@media(max-width:600px) {
    .left_column {
        width: 90vw;
        display: flex;
        flex-direction: column;
    }

    .flex-row_write_again {
        display: flex;
        flex-direction: column-reverse;
    }
}

.writing_button_top_margin {
    margin-top: 76px;
}

.margin-top-small {
    margin-top: 5px;
}

.margin-bottom-small {
    margin-bottom: 20px;
}

.margin-top-med {
    margin-top: 20px;
}

.margin-bottom-med {
    margin-bottom: 45px;
}

.margin-bottom-large {
    margin-bottom: 100px;
}

.margin-top-large {
    margin-top: 100px;
}

.margin_right {
    margin-right: 10px;
}

.margin-left-small {
    margin-left: 20px;
}

.margin_left_button {
    margin-left: 10px;
    min-width: 88px;
}

.social-media-list {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    width: 140px;
    justify-content: space-between;
}

.social-media-list li {
    align-self: center;
}

.social-media-list li img {
    width: 25px;
    height: auto;
}

.main-pencil-container {
    width: 25%;
}

.main-pencil-container img {
    width: 75%;
    margin-top: 240px;
    margin-left: 30px;
}

.header_wrapper {
    position: relative;
}

.pencil_small {
    position: absolute;
    top: 12px;
    left: -60px;
    width: 50px;
    height: auto;
    display: inline;
}

form {
    max-width: 470px;
}

form ul li ul {}

form ul li ul li {
    display: flex;
    justify-content: space-between;
}

@media(max-width: 500px) {
    form ul li ul li {
        flex-direction: column;
    }
}

form ul li ul li div {
    width: 48.5%;

}

.divider {
    width: 100%;
    border-top: 1px solid #cccccc;
}

label {}

.label-margin_small {
    margin-bottom: 3px;
}

select {
    width: 100%;
}

@media(max-width:600px) {
    .mobile_center {
        text-align: center;
    }

    .mobile_center>.right_align {
        text-align: center;
    }
}

input,
input[type=text],
input[type=tel],
input[type=email],
input[type=number] {
    border-radius: 8px;
    padding: .5em 1em;
    border: 1px #cccccc solid;
    width: 48.5%;
    -webkit-appearance: none;

}

input.full_length {
    width: 100%;
}

input.red-box {
    border-radius: 0px;
    padding: .5em 1em;
    border: 1.5px #ba0c2f solid;
    width: 150px;
}


input[type=text].room_number {
    width: 65px;
}

input [type=number] .dropdown {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: .5em 1em;
    border: 1px #cccccc solid;
    border-radius: 8px;
    background: white;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url('../images/dropdown_arrow.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: 15px auto, 100%;
}



.mobile_form_show {
    display: none;
}

@media(max-width:500px) {
    .mobile_form_hide {
        display: none;
    }

    .mobile_form_show {
        display: block;
    }

    input[type=text],
    input[type=number] {
        width: 100%;
    }

    .mobile_form_margin_bottom {
        margin-bottom: 20px;
    }

    .mobile_med_margin_bottom {
        margin-bottom: 40px;
    }

    .mobile_small_margin_top {
        margin-bottom: 20px;
    }
}

.notification_banner {
    width: 100%;
    min-height: 70px;
    background: #BA0C2F;
    background-image: url('../images/megaphone_alert.svg');
    background-repeat: no-repeat;
    padding: .9em 2em .8em 8em;
    background-position: left 0 bottom 0, 0 0;
    background-size: 100px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notification_banner h2 {
    font-weight: 400;
    font-size: 22px;
}

@media(max-width:600px) {
    .notification_banner {
        background-image: none;
        padding: .9em 5vw .9em 5vw;
        width: 100vw;
        margin-left: -5vw;
    }

    .notification_banner h2 {
        font-size: 22px;
    }
}

.challenge_column_wrapper {
    justify-content: space-between;
}

.challenge_column {
    max-width: 300px;
    flex-basis: 100%;
}

.results_container_small {
    background-image: url('../images/vertical_bar_small.svg');
    background-repeat: no-repeat;
    background-position: left 127px top 0, 0 0;
    background-size: auto 63px;
}

.week_results {}

.week_results li {
    font-size: 11px;
    line-height: 1.7em;
    color: #333333;
    font-weight: 400;
    min-width: 20px;
    text-align: center;
    margin-right: 5px;
}


.week_results li:nth-child(5) {
    margin-right: 15px;
}

.week_results li img {
    width: 20px;
    height: auto;
}

@media(max-width:400px) {
    .week_results li {}

    .week_results li img {}
}

.half_width_containers {}

.half_container,
.half_width_containers>li {
    width: 50%;
}

@media(max-width:600px) {

    .half_container,
    .half_width_containers>li {
        width: 100%;
    }
}

.half_width_containers li img {
    max-width: 200px;
    height: auto;
}

.justify_center {
    justify-self: center;
}

.timer-button-center {
    align-items: center;
}

@media(max-width:1000px) {
    .timer-button-center {
        margin-top: 60px;
    }
}

.wrap {
    flex-wrap: wrap;
}

.align_right {
    text-align: right;
}

@media(max-width:600px) {
    .align_right {
        text-align: left;
    }
}

.flex_align_right {
    justify-content: flex-end;
}

.current_progress {
    background-image: url('../images/vertical_bar.svg');
    background-repeat: no-repeat;
    background-position: left 241px top 0, 0 0;
}

.current_progress li {
    font-size: 18px;
    line-height: 1.7em;
    color: #333333;
    font-weight: 300;
    min-width: 42px;
    text-align: center;
    margin-right: 6px;
}

.current_progress li img {
    width: 32px;
    height: auto;
}

@media(max-width:400px) {

    .current_progress,
    .results_container_small {
        background-image: url('../images/vertical_bar.svg');
        background-position: left 236px top 0, 0 0;
        background-size: auto;
    }

    .current_progress li,
    .week_results li {
        min-width: 36px;
        margin-right: 12px;
        font-size: 18px;
    }

    .current_progress li img,
    .week_results li img {
        width: 36px;
    }
}

.stats_number {
    font-size: 40px;
    font-weight: 300;
    line-height: 1em;
}

.stats_cat {
    font-size: 18px;
    font-weight: 300;
    line-height: 1em;
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        transform-origin: center;
    }
}

.download_arrow {
    width: 15px;
    height: auto;
}

.mobile_hide {
    display: none;
}

.desktop_hide {
    display: flex;
    justify-content: center;
}

@media(max-width:600px) {
    .mobile_hide {
        display: flex;
        justify-content: center;
    }

    .desktop_hide {
        display: none;
    }
}

a,
a:visited {
    text-decoration: none;
    color: #BA0C2F;
}

.underlined {
    text-decoration: underline;
}



footer {
    width: 100%;
    background: #BA0C2F;
    min-height: 100px;
}

.footer_inner_wrapper {
    width: 90vw;
    max-width: 800px;
    margin: 0 auto 0 auto;
    padding: 25px 0;
    align-items: center;
}

.footer_inner_wrapper p {
    font-size: 13px;
}

.footer_inner_wrapper img {
    height: 50px;
    width: auto;
}

.timer-input input {
    width: 150px;
    border: none;
    padding: 0;
    text-align: right;
    color: #BA0C2F;
    font-weight: 300;
    font-size: 93px;
    margin-right: -20px;
    outline: 0;
}



.timer-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 10px;
}

.write-again-column {

    /* position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 5%; */


}

.not-a-timer {
    font-size: 2.6em;
    font-weight: 200;


}

.timer-input p {
    color: #BA0C2F;
    font-weight: 200;
    font-size: 93px;
}

.timer-input div button {
    margin-right: 20px;
}


.timer-button {
    position: fixed;
    transform: translate(-50%, -50%);
    /*top: 40vh;*/
}

@media (max-width: 1050px) {
    .main_wrapper.timer {
        margin: unset;
        display: flex;
        width: 100vw;
        max-width: 100vw;
        justify-content: flex-end;
    }

    .main_wrapper.timer>section {
        margin: unset;
        margin-right: 40px;
    }

    .main_wrapper.timer>section>div:not(.timer-active) .right_timer_column {
        width: 400px;
        margin-left: auto;
    }

    .main_wrapper.timer>section .right_timer_column .flex-row_timer {
        justify-content: center;
        margin-bottom: 10px;
    }

    .left_timer_column {
        width: unset;
    }

    .right_timer_column {
        width: unset;
    }

    .timer-input input,
    .timer-input p {
        font-size: 55px !important;
        line-height: 1em;
        max-height: 55px;
        max-width: 80px;
    }

    .arrow-buttons:first-child {
        margin-bottom: 30px !important;
    }

}

@media (max-width:800px) {
    .main_wrapper.timer {
        justify-content: center;
    }


    .main_wrapper.timer>section.flex-row_write_again {
        margin-right: unset;
        flex-direction: column-reverse;
        margin: auto;
    }

    .flex_align_right.margin-top-large.right_timer_column {
        align-self: flex-end;
    }

    .left_timer_column {
        position: relative;
        width: unset;
        align-self: center;
        margin-top: 20px
    }

    .timer-button {
        position: relative;
        top: 0;
        min-width: 220px;
        left: 50vw;
        transform: unset;
    }


    .timer-input input,
    .timer-input p {
        font-size: 55px !important;
        line-height: 1em;
        max-height: 55px;
        max-width: 90px;
    }

    .arrow-buttons:first-child {
        margin-bottom: 20px !important;
    }
}

@media(max-width:500px) {
    .flex-column_timer {
        flex-direction: column;
    }

    .main_wrapper.timer>section .right_timer_column {
        width: unset;
        margin-left: unset;
    }

    .left_timer_column {
        width: unset;
        position: relative;
        align-self: center;
    }

    .main_wrapper.timer>section {
        margin: auto;
        padding: 10px;
    }

    /* .write-again-column {
        position: relative;
        top: auto;
        transform: none;
        left: auto;
        align-self: center;
        margin-top: 100px;
    } */



    .timer-center {
        max-width: 90vw;
    }

    .timer-input input {
        font-size: 70px;
        width: 100%;
    }

    .timer-input p {
        font-size: 70px;
    }

    .timer-input input,
    .timer-input p {
        font-size: 40px !important;
        line-height: 1em;
        max-height: 40px;
        max-width: 70px;
    }

    .arrow-buttons:first-child {
        margin-bottom: 10px !important;
    }
}


.arrow-buttons img {
    width: 30px;
    height: auto;

}

.arrow-buttons {
    border: none;
    background: none;
    cursor: pointer;
}

.arrow-buttons:first-child {
    margin-bottom: 40px;
}


iframe {
    border: 1.5px #ba0c2f solid;
}

.animation {

    position: fixed;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    transform: translateX(0) scale(1);
    transition: transform .5s;
}

.animation.animation-left {
    height: 500px;
    max-height: 500px;
    min-height: 500px;
}

@media (max-width: 900px) {
    .animation.animation-left {
        height: 350px;
        max-height: 350px;
        min-height: 350px;
    }
}

@media (max-width: 800px) {
    .animation.animation-left {
        display: none;
    }
}

.animation-left.animation svg {
    transform: scale(.75);
    margin: unset;
    transition: transform .5s;
}

.animation svg {
    height: 100%;
    margin: 0 auto;
    display: block;
    transform: scale(1.5)
}

.animation .darken {
    opacity: 1;
}

.animation path {
    transition: opacity .3s;
    opacity: 0.2;
    animation: tick 24s infinite;
    animation-play-state: paused;
}

.animation.animating path {
    animation-play-state: running;
}



.animation path:nth-child(2) {
    animation-delay: 1s;
}

.animation path:nth-child(3) {
    animation-delay: 2s;
}

.animation path:nth-child(4) {
    animation-delay: 3s;
}

.animation path:nth-child(5) {
    animation-delay: 4s;
}

.animation path:nth-child(6) {
    animation-delay: 5s;
}

.animation path:nth-child(7) {
    animation-delay: 6s;
}

.animation path:nth-child(8) {
    animation-delay: 7s;
}

.animation path:nth-child(9) {
    animation-delay: 8s;
}

.animation path:nth-child(10) {
    animation-delay: 9s;
}

.animation path:nth-child(11) {
    animation-delay: 10s;
}

.animation path:nth-child(12) {
    animation-delay: 11s;
}

.animation path:nth-child(13) {
    animation-delay: 12s;
}

.animation path:nth-child(14) {
    animation-delay: 13s;
}

.animation path:nth-child(15) {
    animation-delay: 14s;
}

.animation path:nth-child(16) {
    animation-delay: 15s;
}

.animation path:nth-child(17) {
    animation-delay: 16s;
}

.animation path:nth-child(18) {
    animation-delay: 17s;
}

.animation path:nth-child(19) {
    animation-delay: 18s;
}

.animation path:nth-child(20) {
    animation-delay: 19s;
}

.animation path:nth-child(21) {
    animation-delay: 20s;
}

.animation path:nth-child(22) {
    animation-delay: 21s;
}

.animation path:nth-child(23) {
    animation-delay: 22s;
}

.animation path:nth-child(24) {
    animation-delay: 23s;
}

.animation button {
    position: absolute;
    bottom: 10px;
    right: 10px;
}


/* each second of a 24-second animation = 4.1667% */
@keyframes tick {
    0% {
        opacity: .2;
    }

    4.1666666666666666666666% {
        opacity: 1;
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: .2;
    }
}

.edit-pencil {
    padding-left: 5px;
    display: inline;
    width: 25px;
    cursor: pointer;
}

.edit-text {
    cursor: pointer;
    font-size: 12px;
    line-height: 1.7em;
    color: #333333;
    font-weight: 100;
}

.h2_button_padding+button {
    margin-top: 30px;
}

.hello-text {
    text-align: right;
    color: #BA0C2F;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}



.border-top-gray {
    border-top: 1px solid silver;
    padding-top: .5em;
}

/*messaging styling*/
.team-chat {
    display: flex;
    width: 80vw;
    flex-direction: row;
    min-height: 310px;
}



.team-chat .chat-left {
    display: flex;

    flex-direction: column;
    min-width: 180px;
    margin-right: 70px;
}

.team-chat .chat-right {
    display: flex;
    width: calc(100% - 200px);
    max-width: 475px;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.team-chat .chat-right .member-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.member-text,
.chat-area {
    color: #333333;
}

.teammembers {
    border-left: 4px solid #BA0C2F;
}

.teammembers li {
    padding: .5em;
    border: 1px solid #BA0C2F;
    border-left: 0;
    border-radius: 0 5px 5px 0;
    margin-bottom: 5px;
    cursor: pointer;
    color: #BA0C2F;
    width: 90%;
    position: relative;
}

.italic {
    font-style: italic;
}

.teammembers li.active {
    background: #BA0C2F;
    color: white;
    width: 100%;
}

.member-info .margin-top-med {
    margin-top: 0;
}

.member-info .margin-top-med .margin-top-small {
    margin-top: 0;
}

.chat-area {
    /* background: #e8e8e8; */
    width: 100%;
    overflow: auto;
    height: 150px;
    padding-right: 10px;
}

.message {
    font-size: 14px;
    margin-bottom: 8px;
}

.message .name {
    margin-right: 5px;
}

.message-columns {
    display: flex;
    flex-direction: row;
}

.message-columns li:first-child {
    min-width: 70px;
}

.message-text {
    line-height: 1.5em;
}

.chat-submission {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.chat-submission input {
    width: calc(100% - 50px);

}

.chat-submission img {
    height: 28px;
    width: auto;
    margin-left: 5px;
}

.goal-text {
    font-style: italic;
    font-weight: 300;
}

.badge {
    position: absolute;
    top: 10px;
    right: 0px;
    transform: translate(50%, -50%);
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    color: white;
    font-size: 12px;
}

.badge.hidden {
    display: none;
}

.badge::after {
    /*content: '\02605';*/
    content: '';
    font-size: 30px;
    color: #BA0C2F;
    background-color: #BA0C2F;
    position: absolute;
    z-index: -1;
    height: 30px;
    width: 30px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.new-line {
    height: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 70px;
}

.new-line div {
    height: 1px;
    width: 100%;
    background-color: #ba0c2f;
    z-index: 1;
}

.new-line span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 0 10px;
    background-color: #ba0c2f;
    color: white;
    font-size: 14px;
}


@media(max-width:600px) {
    .team-chat {
        flex-direction: column;
        width: 100%;

    }

    .team-chat .chat-right {
        margin-top: 45px;
        max-width: 100%;
        width: 100%;
    }
}

/*end messaging styling*/


/*start achievements styling*/
.achievements-container {
    /* border:1px solid gray; */
    /* padding:30px 30px 0; */
    width: 100%;
    /* margin-top: 100px; */
}

.achievements-container li {

    align-items: flex-start;
}

.achievements-container .icon-size {
    width: 55px;
    height: auto;
    margin: 0 40px 0 15px;
    align-self: center;
    flex: 0 0 55px;
}

.achievement-date {
    font-size: .9em;
    font-weight: 300;
    color: #333333;
}

.scrolling-box {
    height: 220px;
    overflow: auto;
}

.scrolling-box::-webkit-scrollbar-track {
    background-color: #BBBB;
}

.scrolling-box::-webkit-scrollbar {
    width: 4px;
    background-color: #BBBB;
}

.scrolling-box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #BA0C2F;
    border-radius: 4px;
}


.achievment-row {
    width: 100%;
    max-width: 640px;
    margin-bottom: 10px;
    min-height: 72px;
}

/*end achievements styling*/