.certificate-container {
	height:100vh;
	width:100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	transform:scale(.8);
}
.certificate-container .certificate {
	height:100%;
	width:100%;
	max-height:8.5in;
	max-width:11in;
	box-sizing: border-box;
	padding:.5in .5in .5in .5in;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	text-align: right;
	border:1px solid gray;
	overflow: hidden;
	position: relative;
}

.certificate .top {
	margin-top: auto;
}

.certificate h1 {
	font-size: 80pt;
	color:#BA0C2F;
	margin:.4em 0;
	line-height: 1;
}

.certificate h2 {
	font-size: 24pt;
	color:#BA0C2F;
	margin-bottom: .5em;
}

.certificate p {
	font-size: 18pt;
	font-style: italic;
}

.certificate time, .certificate output {
	font-weight: bold;
	color:#BA0C2F;
}

.certificate ul {
	margin-top: 2em;
}

.certificate .logo {
	width:1.5in;
	height:auto;
	margin-top: 3em;
}

.certificate .animation {
	position: absolute;
	transform: translateX(-50%) scale(.75);
	/*This is strange but fixes a bug with translate while printing*/
	top:-1%;
}
@media print {
	html, body {
		overflow: hidden;
		height:100vh;
		width:100vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.certificate-container {
		transform:none;
		position: absolute;
		top:0;
		left: 0;
	}
	.certificate-container .certificate {
		max-height: none;
		max-width: none;
		height:100vh;
		width:100vw;
		border:none;
	}
}